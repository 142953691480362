export const cn = (
  ...args: Array<string | undefined | null | []>
): string | undefined => {
  const classNames = args.reduce<string[]>((prev, current) => {
    if (!current) return prev;

    prev.push(Array.isArray(current) ? current.join(" ") : current);

    return prev;
  }, []);

  return classNames?.length > 0 ? classNames.join(" ") : undefined;
};

export const classnames = cn;
